const hreflangConfig = {
    '/fr/': {
        'fr': 'https://staging.kretzrealestate.com/fr/',
        'en': 'https://staging.kretzrealestate.com/en/',
        'es': 'https://staging.kretzrealestate.com/es/',
        'pt': 'https://staging.kretzrealestate.com/pt/'
    },
    '/fr/buy/': {
        'fr': 'https://staging.kretzrealestate.com/fr/buy/',
        'en': 'https://staging.kretzrealestate.com/en/buy/',
        'es': 'https://staging.kretzrealestate.com/es/buy/',
        'pt': 'https://staging.kretzrealestate.com/pt/buy/'
    },
    '/fr/sell/': {
        'fr': 'https://staging.kretzrealestate.com/fr/sell/',
        'en': 'https://staging.kretzrealestate.com/en/sell/',
        'es': 'https://staging.kretzrealestate.com/es/sell/',
        'pt': 'https://staging.kretzrealestate.com/pt/sell/'
    },
    '/fr/biens-en-location/': {
        'fr': 'https://staging.kretzrealestate.com/fr/biens-en-location/',
        'en': 'https://staging.kretzrealestate.com/en/biens-en-location/',
        'es': 'https://staging.kretzrealestate.com/es/biens-en-location/',
        'pt': 'https://staging.kretzrealestate.com/pt/biens-en-location/'
    },
    '/fr/agency/': {
        'fr': 'https://staging.kretzrealestate.com/fr/agency/',
        'en': 'https://staging.kretzrealestate.com/en/agency/',
        'es': 'https://staging.kretzrealestate.com/es/agency/',
        'pt': 'https://staging.kretzrealestate.com/pt/agency/'
    },
    '/fr/off-market/': {
        'fr': 'https://staging.kretzrealestate.com/fr/off-market/',
        'en': 'https://staging.kretzrealestate.com/en/off-market/',
        'es': 'https://staging.kretzrealestate.com/es/off-market/',
        'pt': 'https://staging.kretzrealestate.com/pt/off-market/'
    },
    '/fr/alert/': {
        'fr': 'https://staging.kretzrealestate.com/fr/alert/',
        'en': 'https://staging.kretzrealestate.com/en/alert/',
        'es': 'https://staging.kretzrealestate.com/es/alert/',
        'pt': 'https://staging.kretzrealestate.com/pt/alert/'
    },
    '/fr/annonce': {
        'fr': 'https://staging.kretzrealestate.com/fr/annonce',
        'en': 'https://staging.kretzrealestate.com/en/annonce',
        'es': 'https://staging.kretzrealestate.com/es/annonce',
        'pt': 'https://staging.kretzrealestate.com/pt/annonce'
    },
    '/fr/haussmannian-apartment/': {
        'fr': 'https://staging.kretzrealestate.com/fr/haussmannian-apartment/',
        'en': 'https://staging.kretzrealestate.com/en/haussmannian-apartment/',
        'es': 'https://staging.kretzrealestate.com/es/haussmannian-apartment/',
        'pt': 'https://staging.kretzrealestate.com/pt/haussmannian-apartment/'
    },
    '/fr/dream-villa/': {
        'fr': 'https://staging.kretzrealestate.com/fr/dream-villa/',
        'en': 'https://staging.kretzrealestate.com/en/dream-villa/',
        'es': 'https://staging.kretzrealestate.com/es/dream-villa/',
        'pt': 'https://staging.kretzrealestate.com/pt/dream-villa/'
    },
    '/fr/family-chalets/': {
        'fr': 'https://staging.kretzrealestate.com/fr/family-chalets/',
        'en': 'https://staging.kretzrealestate.com/en/family-chalets/',
        'es': 'https://staging.kretzrealestate.com/es/family-chalets/',
        'pt': 'https://staging.kretzrealestate.com/pt/family-chalets/'
    },
    '/fr/typical-space/': {
        'fr': 'https://staging.kretzrealestate.com/fr/typical-space/',
        'en': 'https://staging.kretzrealestate.com/en/typical-space/',
        'es': 'https://staging.kretzrealestate.com/es/typical-space/',
        'pt': 'https://staging.kretzrealestate.com/pt/typical-space/'
    },
    '/fr/garden-terrace/': {
        'fr': 'https://staging.kretzrealestate.com/fr/garden-terrace/',
        'en': 'https://staging.kretzrealestate.com/en/garden-terrace/',
        'es': 'https://staging.kretzrealestate.com/es/garden-terrace/',
        'pt': 'https://staging.kretzrealestate.com/pt/garden-terrace/'
    },
    '/fr/private-hotel/': {
        'fr': 'https://staging.kretzrealestate.com/fr/private-hotel/',
        'en': 'https://staging.kretzrealestate.com/en/private-hotel/',
        'es': 'https://staging.kretzrealestate.com/es/private-hotel/',
        'pt': 'https://staging.kretzrealestate.com/pt/private-hotel/'
    },
    '/fr/agency#tech': {
        'fr': 'https://staging.kretzrealestate.com/fr/agency/#tech',
        'en': 'https://staging.kretzrealestate.com/en/agency/#tech',
        'es': 'https://staging.kretzrealestate.com/es/agency/#tech',
        'pt': 'https://staging.kretzrealestate.com/pt/agency/#tech'
    },
    '/fr/search': {
        'fr': 'https://staging.kretzrealestate.com/fr/search',
        'en': 'https://staging.kretzrealestate.com/en/search',
        'es': 'https://staging.kretzrealestate.com/es/search',
        'pt': 'https://staging.kretzrealestate.com/pt/search'
    },
    '/fr/domain-and-castel': {
        'fr': 'https://staging.kretzrealestate.com/fr/domain-and-castel',
        'en': 'https://staging.kretzrealestate.com/en/domain-and-castel',
        'es': 'https://staging.kretzrealestate.com/es/domain-and-castel',
        'pt': 'https://staging.kretzrealestate.com/pt/domain-and-castel'
    },
    '/fr/dream-villa': {
        'fr': 'https://staging.kretzrealestate.com/fr/dream-villa',
        'en': 'https://staging.kretzrealestate.com/en/dream-villa',
        'es': 'https://staging.kretzrealestate.com/es/dream-villa',
        'pt': 'https://staging.kretzrealestate.com/pt/dream-villa'
    },
    '/fr/family-chalets': {
        'fr': 'https://staging.kretzrealestate.com/fr/family-chalets',
        'en': 'https://staging.kretzrealestate.com/en/family-chalets',
        'es': 'https://staging.kretzrealestate.com/es/family-chalets',
        'pt': 'https://staging.kretzrealestate.com/pt/family-chalets'
    },    
    '/fr/family-chalets': {
        'fr': 'https://staging.kretzrealestate.com/fr/family-chalets',
        'en': 'https://staging.kretzrealestate.com/en/family-chalets',
        'es': 'https://staging.kretzrealestate.com/es/family-chalets',
        'pt': 'https://staging.kretzrealestate.com/pt/family-chalets'
    },
    '/fr/typical-space': {
    'fr': 'https://staging.kretzrealestate.com/fr/typical-space',
    'en': 'https://staging.kretzrealestate.com/en/typical-space',
    'es': 'https://staging.kretzrealestate.com/es/typical-space',
    'pt': 'https://staging.kretzrealestate.com/pt/typical-space'
},
'/fr/garden-terrace': {
    'fr': 'https://staging.kretzrealestate.com/fr/garden-terrace',
    'en': 'https://staging.kretzrealestate.com/en/garden-terrace',
    'es': 'https://staging.kretzrealestate.com/es/garden-terrace',
    'pt': 'https://staging.kretzrealestate.com/pt/garden-terrace'
},
'/fr/private-hotel': {
    'fr': 'https://staging.kretzrealestate.com/fr/private-hotel',
    'en': 'https://staging.kretzrealestate.com/en/private-hotel',
    'es': 'https://staging.kretzrealestate.com/es/private-hotel',
    'pt': 'https://staging.kretzrealestate.com/pt/private-hotel'
},
'/fr/haussmannian-apartment': {
    'fr': 'https://staging.kretzrealestate.com/fr/haussmannian-apartment',
    'en': 'https://staging.kretzrealestate.com/en/haussmannian-apartment',
    'es': 'https://staging.kretzrealestate.com/es/haussmannian-apartment',
    'pt': 'https://staging.kretzrealestate.com/pt/haussmannian-apartment'
}

  };
  
  export default hreflangConfig;
  