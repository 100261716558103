import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'
import queryString from 'query-string'
import { useQueryParam, NumberParam } from 'use-query-params'

import PropertySort from './PropertySort'
import CancelBadge from './../CancelBadge'
import { PropertyShowcase } from './../ProperytShowcase'
import { SearchContext } from './../../contexts/searchContext'
import { STATUS_A_LOUER, STATUS_SAISONNIER } from '../../utils/constants'
import { capitalize } from './../../utils/helper'
import paginate from './../../utils/paginate'
import { filterResult, sortResult } from './../../utils/search'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { graphql, useStaticQuery } from 'gatsby'

const useStyles = (bgClr: string) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      backgroundColor: bgClr,
      flexWrap: 'wrap',
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.between('lg', 'xl')]: {
        paddingTop: theme.spacing(12),
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        paddingTop: theme.spacing(8),
      },
    },
    showcase: {
      marginTop: '1.5rem',
      marginBottom: '1.5rem',
      justifyContent: 'center',
      maxWidth: '1400px',
      width: '100%',
    },
    contentPropertyList: {
      margin: 0,
      width: '100%',
      display: 'grid',
      padding: 0,
      justifyContent: 'center',
      gridTemplateColumns: 'repeat(auto-fit, minmax(0, 35rem))',
      ['@media (min-width:600px)']: {
        gap: '50px',
      },
    },
    liPropertyList: {
      position: 'relative',
      listStyle: 'none',
      [theme.breakpoints.between('xs', 'sm')]: {
        paddingBottom: theme.spacing(5),
      },
    },
    filter: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      [theme.breakpoints.between('md', 'md')]: {
        flexBasis: '85%',
      },
    },
    paginate: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
      margin: 'auto',
      listStyle: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.between('xs', 'xs')]: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
      },
    },
    paginateItem: {
      margin: '0 .5rem',
      border: 'none',
      background: 'transparent',
      '&:hover': {
        cursor: 'pointer',
      }
    },
    anchorLink: {
      textDecoration: 'none',
      color: 'black',
      padding: '1px 6px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    currentItem: {
      textDecoration: 'underline',
    },
    status: {
      fontFamily: "'Cormorant Garamond', serif",
      position: 'absolute',
      left: 0,
      right: 0,
      top: '-1.5rem',
      fontSize: '2rem',
      fontWeight: 600,
      zIndex: 1,
      textShadow:
        '-6px 0 1.2rem #FAE9E5, 0 6px 1.2rem #FAE9E5, 6px 0 1.2rem #FAE9E5, 0 -6px 1.2rem #FAE9E5',
    },
  }))

interface PropertyListProps {
  bgClr?: string
  filterBackgroundColor?: string
  data: Array<any>
  location: any
  withFilter?: boolean
}

const getCityName = (
  bien: { autreVille: any; villeFr: any; villeEs: any; villeEn: any; villePt: any; ville: any },
  locale: string,
) => {
  switch (locale) {
    case 'fr':
      return bien?.autreVille || bien.villeFr || bien.ville
    case 'es':
      return bien?.autreVille || bien.villeEs || bien.ville
    case 'en':
      return bien?.autreVille || bien.villeEn || bien.ville
    case 'pt':
      return bien?.autreVille || bien.villePt || bien.ville
    default:
      return bien?.autreVille || bien.ville
  }
}

export const PropertyList: React.FC<PropertyListProps> = (props) => {
  const { bgClr = '#FAE9E5', filterBackgroundColor = '', data, location, withFilter } = props
  const classes = useStyles(bgClr)()
  const intl = useIntl()
  const [currentPath, setCurrentPath] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const url = window.location.href
      const path = url.split(`/${intl.locale}/`)[1]
      setCurrentPath(path ? path.split('/?page')[0] : '')
    }
  }, [intl.locale]) // The effect runs whenever the locale changes

  const QScurrentPage = queryString.parse(location?.search).page
  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam)
  const {
    searchState,
    searchState: { propertyType },
    setPropertyType,
  } = useContext(SearchContext)

  const filtered = withFilter ? filterResult(searchState, data) : data
  const result = sortResult(searchState, filtered)
  const { pages, currentPageItems, totalPages } = paginate(
    result ?? data,
    Number(QScurrentPage ?? 1),
  )

  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const theLink = `${dataCanonical.site.siteMetadata.siteUrl}`
  return (
    <Grid container className={classes.root} id="result">
      <Grid container className={classes.showcase} justify="space-evenly">
        <Grid container className={classes.filter}>
          <Box>
            <Grid container style={{ backgroundColor: 'none' }}>
              {Object.keys(propertyType).map((key) => (
                <CancelBadge
                  key={key}
                  text={intl.formatMessage({ id: key })}
                  id={key}
                  backgroundColor={filterBackgroundColor ? filterBackgroundColor : ''}
                  onRemove={() => {
                    setPropertyType({ name: key, value: false })
                  }}
                />
              ))}
            </Grid>
          </Box>
          <Box>
            <PropertySort />
          </Box>
        </Grid>
        <ul className={classes.contentPropertyList}>
          {currentPageItems.map((bien: any) => (
            <li key={bien.reference + '_liPropertyList'} className={classes.liPropertyList}>
              {['SOP_B', 'OAC_B'].includes(bien.statutVente) &&
                !bien.isOffmarket &&
                !bien.isProtected && (
                  <div className={classes.status}>
                    {intl.formatMessage({ id: bien.statutVente }).toUpperCase()}
                  </div>
                )}
              {!['SOP_B', 'OAC_B'].includes(bien.statutVente) && bien.isOffmarket && (
                <div className={classes.status}>{'OFF MARKET'}</div>
              )}
              <PropertyShowcase
                propertyTypeSlug={bien.propertyTypeSlug}
                confidentialPrice={bien.prixConfidentiel}
                reference={bien.reference}
                title={
                  bien[`titre${capitalize(intl.locale)}`]
                    ? bien[`titre${capitalize(intl.locale)}`]
                    : bien.titreFr
                      ? bien.titreFr
                      : bien.infos
                }
                area={`${bien.surfaceHabitable} ${bien.unitArea == 'M2'
                  ? intl.formatMessage({ id: 'squareMeter' })
                  : intl.formatMessage({ id: 'squareFeet' })
                  }`}
                ville={getCityName(bien, intl.locale)}
                price={
                  STATUS_A_LOUER.includes(bien.statutVente)
                    ? bien.prixLoyer
                    : STATUS_SAISONNIER.includes(bien.statutVente)
                      ? bien.prixSaisonnierMin
                      : bien.prixAffichageFai
                }
                priceMax={
                  STATUS_SAISONNIER.includes(bien.statutVente) ? bien.prixSaisonnierMax : null
                }
                size={`${bien.nombreChambres} ${intl.formatMessage({
                  id: 'chamber' + (bien.nombreChambres && bien.nombreChambres > 1 ? 's' : ''),
                })}`}
                pieces={`${bien.nombrePieces} ${intl.formatMessage({
                  id: 'piece' + (bien.nombrePieces && bien.nombrePieces > 1 ? 's' : ''),
                })}`}
                details={bien[`description${capitalize(intl.locale)}`]}
                src={bien?.photosKp.slice(0, 5) ?? []}
                isOffmarket={bien.isOffmarket}
                suiviPar={bien.suiviPar}
                showTo
                unitPrice={bien.unitPrice}
                unitArea={bien.unitArea}
                isRented={STATUS_A_LOUER.includes(bien.statutVente)}
                isSeasonal={STATUS_SAISONNIER.includes(bien.statutVente)}
              />
            </li>
          ))}
        </ul>
      </Grid>
      <ul className={classes.paginate}>
        {pages.map((page, index) => (
          <>
            {!!(index + 1 !== page && index === 0) && (
              <AnchorLink to={`${theLink}/${intl.locale}/${currentPath}?page=${index + 1}`} className={`${classes.paginateItem} ${classes.anchorLink}`}>
                {'<'}{' '}
              </AnchorLink>
            )}
            <li key={page + '_liPropertyListPagination'} className={classes.paginateItem}>
              <AnchorLink to={`${theLink}/${intl.locale}/${currentPath}?page=${page}`} className={`${classes.paginateItem} ${classes.anchorLink} ${page === currentPage ? classes.currentItem : ''}`}>
                {page}
              </AnchorLink>
            </li>
            {index + 1 === pages.length && page !== totalPages && (
              <AnchorLink to={`${theLink}/${intl.locale}/${currentPath}?page=${totalPages}`} className={`${classes.paginateItem} ${classes.anchorLink}`}>
                {'>'}{' '}
              </AnchorLink>
            )}
          </>
        ))}
      </ul>
    </Grid>
  )
}

export default PropertyList
